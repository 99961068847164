import React from 'react';
import Main from './Main';
import Watchlist from './Watchlist';
import Meta from './Meta';
import Review from './Review';
import API from './API';
import Legal from './Legal';
import ScrollToTop from './ScrollToTop'
import { Switch, Route, BrowserRouter, Redirect, withRouter, Link } from 'react-router-dom';
import Helmet from 'react-helmet'
import images from './images.js'

function RouteWithTitle({ title, ...props }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Route {...props} />
    </>
  )
}


const NoMatchPage = () => {
  return (
    <>
      <Helmet>
        <title>{'404 Not Found - Anime Track'}</title>
        <meta
          name="description"
          content={'404 Not Found - Anime Track'}
        />
      </Helmet>
      <center>
        <img src={images.surrender} alt="Anime Track 404" style={{ width: '50%', maxWidth: '360px', marginTop: '40px' }} />
        <h3>404: No anime here</h3>
        <Link className={'main-links'} style={{ margin: '0', marginTop: '20px' }} to={'/series/'}>Go home</Link>
      </center>
    </>
  )
}

function App() {
  return (
      <BrowserRouter>
        <ScrollToTop>
          <main>
            <Switch>
                <Route path="/" exact>
                  <Redirect to="/series" />
                </Route>
                <Route path="/series" exact>
                  <Redirect to="/series/new" />
                </Route>
                <Route path="/movies" exact>
                  <Redirect to="/movies/new" />
                </Route>
                <Route path="/watchlist" exact>
                  <Redirect to="/watchlist/series" />
                </Route>
                <Route path="/watchlist/series" exact>
                  <Redirect to="/watchlist/series/new" />
                </Route>
                <RouteWithTitle path="/movies/new" component={withRouter(Main)} />
                <RouteWithTitle path="/series/new" component={withRouter(Main)} />
                <RouteWithTitle path="/movies/popular" component={withRouter(Main)} />
                <RouteWithTitle path="/series/popular" component={withRouter(Main)} />
                <RouteWithTitle path="/movies/best" component={withRouter(Main)} />
                <RouteWithTitle path="/series/best" component={withRouter(Main)} />
                <RouteWithTitle path="/watchlist/movies/new" component={withRouter(Watchlist)} />
                <RouteWithTitle path="/watchlist/series/new" component={withRouter(Watchlist)} />
                <RouteWithTitle path="/watchlist/movies/best" component={withRouter(Watchlist)} />
                <RouteWithTitle path="/watchlist/series/best" component={withRouter(Watchlist)} />
                <RouteWithTitle path="/title/" component={withRouter(Meta)} />
                <RouteWithTitle path="/review/" component={withRouter(Review)} />
                <RouteWithTitle exact path="/api-docs" component={withRouter(API)} />
                <RouteWithTitle exact path="/legal" component={withRouter(Legal)} />
                <RouteWithTitle component={NoMatchPage} />
            </Switch>
          </main>
        </ScrollToTop>
      </BrowserRouter>
  );
}

export default App;
