import React from 'react';
import './App.css';
import images from './images.js';
import { Link, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet'
import Checkbox from 'rc-checkbox';
import './Checkbox.css';
import ImageFadeIn from "react-image-fade-in"
import searchAddons from './search-addons.js'
import useKitsu from './useKitsu.js'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

function nameFix(string) {
  // converts name in reverse forms ("Edogawa, Conan")
  // to normal forms ("Conan Edogawa")
  return string.split(', ').reverse().join(' ')
}

export default class App extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        addonManager: false,
        update: 1
      };
  }
  componentWillMount() {

      const processMeta = data => {

        const lists = require('./data/lists.json')
        let releaseInfo = lists[data.type][anilistId]
  //      delete releaseInfo.meta
        data.releaseInfo = releaseInfo || {}
  //      console.log(data)

        if (!(window.localStorage || {}).addons)
          window.localStorage.addons = JSON.stringify(['Crunchyroll', 'Funimation', 'VRV', 'VIZ'])

        if (!(window.localStorage || {}).watchlist)
          window.localStorage.watchlist = JSON.stringify({ movie: {}, series: {}, movieWatchlistOrder: [], seriesWatchlistOrder: [] })

        const isAndroid = ((window.navigator || {}).userAgent || '').endsWith('AndroidApp')

        this.setState({ initLoading: false, item: data, isAndroid })
      }

      let locTypes = this.props.location.pathname.split('/')
      const anilistId = locTypes[locTypes.length -1]
      try {
        const data = require('./data/meta/' + anilistId + '.json')
        processMeta(data)
      } catch(e) {
        this.setState({ initLoading: true })
        fetch('https://api.anime-track.app/meta/' + anilistId + '.json')
          .then(res => res.json())
          .then(
            (result) => {
              processMeta(result)
            },
            (error) => {
              this.setState({ initLoading: false })
              // maybe handle errors in the future
            }
          )
      }
  }
  pageTitle() {
     let docTitle = ''

     if (this.state.item.name)
        docTitle += this.state.item.name

      if (this.state.item.year)
        docTitle += ' ' + this.state.item.year

      return docTitle
  }
  pageDescription() {
    let docDesc = ''

    if (this.state.item.name)
      docDesc += this.state.item.name

    if (this.state.item.year)
      docDesc += ' ' + this.state.item.year

    docDesc += ' torrent releases, includes MAL, Anilist, Kitsu scores and reviews.'

    return docDesc
  }
  showAddonManager() {
    this.setState({
      addonManager: true
    })
  }
  addonChange(a,e) {
    const addonName = a
    const checked = ((e || {}).target || {}).checked
    const addons = JSON.parse(window.localStorage.addons)
    if (!checked)
      addons.splice(addons.indexOf(addonName), 1)
    else
      addons.push(addonName)
    window.localStorage.addons = JSON.stringify(addons)
  }
  saveAddons() {
    this.setState({ addonManager: false })
  }
  addToWatchlist() {
    const watchlist = JSON.parse(window.localStorage.watchlist)
    if (!((this.state.item || {}).ids || {}).anilist || !((this.state.item || {}).type))
      return
    const watchlistType = this.state.item.type
    watchlist[watchlistType + 'WatchlistOrder'].unshift(this.state.item.ids.anilist)
    watchlist[watchlistType][this.state.item.ids.anilist] = this.state.item.releaseInfo
    window.localStorage.watchlist = JSON.stringify(watchlist)
    this.setState({ update: this.state.update+1 })
  }
  removeFromWatchlist() {
    const watchlist = JSON.parse(window.localStorage.watchlist)
    const watchlistType = this.state.item.type
    if ((watchlist[watchlistType + 'WatchlistOrder'] || []).includes(((this.state.item || {}).ids || {}).anilist || ''))
      watchlist[watchlistType + 'WatchlistOrder'].splice(watchlist[watchlistType + 'WatchlistOrder'].indexOf(this.state.item.ids.anilist), 1)
    delete watchlist[watchlistType][this.state.item.ids.anilist]
    window.localStorage.watchlist = JSON.stringify(watchlist)
    this.setState({ update: this.state.update+1 })
  }
  agreedLegal() {
    window.localStorage.agreedLegal = '1'
    this.setState({ agreedLegal: true })
  }
  render() {
    if (this.state.initLoading) {
      return (
        <div className="loading">
          <div />
          <div />
        </div>
      )
    }

    const addons = JSON.parse(window.localStorage.addons)
    const watchlist = JSON.parse(window.localStorage.watchlist)

    const data = this.state.item

   if (!data || !Object.keys(data).length) {
     let locTypes = this.props.location.pathname.split('/')
     const anilistId = locTypes[locTypes.length -1]
     if (anilistId && !isNaN(anilistId)) {
      return (
        <>
          <center>
            <div style={{ margin: '30px', marginTop: '50px' }}>This item is no longer available, see it on <a className="blank-link" style={{ textDecoration: 'underline' }} href={"https://anilist.co/anime/" + anilistId} target="_blank">AniList</a>.</div>
            <Link className={'main-links'} style={{ margin: '0', marginTop: '20px' }} to={'/series/'}>Go home</Link>
          </center>
        </>
      )
     } else {
       return <Redirect to={'/404'} />
     }
   }

    let ratings = { __html: '' }
    if ((((data.ratings || {}).mal || {}).audience || {}).score) {
      ratings.__html += '<div class="mal-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + data.ratings.mal.audience.score.toFixed(1) + '/10'
    }
    if ((((data.ratings || {}).anilist || {}).audience || {}).score) {
      if (ratings.__html)
        ratings.__html += '&nbsp;&nbsp;&nbsp;'
      ratings.__html += '<div class="anilist-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + data.ratings.anilist.audience.score + '%'
    }
    if ((((data.ratings || {}).kitsu || {}).audience || {}).score) {
      if (ratings.__html)
        ratings.__html += '&nbsp;&nbsp;&nbsp;'
      ratings.__html += '<div class="kitsu-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + data.ratings.kitsu.audience.score.toFixed(1) + '%'
    }
    if (!ratings.__html)
      ratings.__html = '&nbsp;'


    const genres = { __html: (data.genres || []).length ? data.genres.join(' / ') : '&nbsp;' }

    const searchers = searchAddons.map((el, ij) => {
      if (!addons.includes(el.name))
        return ('')
      let query = data.name
      if (el.alphanumeric)
        query = query.replace(/[^\w\s]/gi, '').trim()
      if (el.noQuotes)
        query = query.split("'").join('').split('"').join('')
      if (data.type === 'movie')
        query += ' ' + data.year
      let url = el.url
      if (el.useIds && el.useIds.length) {
        let missingId = false
        el.useIds.forEach(thisId => {
          if ((data.ids || {})[thisId]) {
            url = url.replace('%%' + thisId + '%%', data.ids[thisId])
          } else
            missingId = true
        })
        if (missingId)
          return ('')
      }
      if (Object.keys(el.types || {}).length) {
        if (el.types[data.type])
          url = url.replace('%%type%%', el.types[data.type])
        else
          return ('')
      }
      if (el.url.includes('%%query'))
        url = url.replace('%%query%%', encodeURIComponent(query).split('%20').join(el.space || '%20'))
      return (
        <a key={'searcher-' + ij} style={{ marginBottom: '15px' }} className={'main-links'} href={url} target="_blank" rel="noopener noreferrer">{el.name}</a>
      )
    })

    searchers.push((
        <span key={'searcher-99'} onClick={this.showAddonManager.bind(this)} style={{ marginBottom: '15px', padding: '10px 15px', height: '21px', verticalAlign: 'top' }} className={'main-links'}>
          +
        </span>
      ))

    const lastRelease = (
          <div style={{ display: 'inline-block', marginRight: '30px', marginBottom: '15px' }}>
            <b>Released</b>
            <div style={{ height: '15px'}}/>
            {new Date(data.releaseInfo.pubdate).toLocaleDateString("en-US")}
          </div>
    )

    let lastEpisode = (<br/>)

    if (data.type === 'series') {
//      let episodeTag = ''
      // if (((data.releaseInfo || {}).episode_info || {}).seasonnum)
      //   episodeTag += 'S' + ('0' + data.releaseInfo.episode_info.seasonnum).slice(-2)
//      if (((data.releaseInfo || {}).episode_info || {}).epnum)
//        episodeTag += ('0' + data.releaseInfo.episode_info.epnum).slice(-2)
//      if (episodeTag)
        lastEpisode = (
          <div style={{ display: 'inline-block', marginRight: '30px', marginBottom: '15px' }}>
            <b>Episode</b>
            <div style={{ height: '15px'}}/>
            {data.releaseInfo.episode_info.epnum}
          </div>
        )
    }

    let actors = ('')

    if ((data.characters || []).length) {
      actors = data.characters.slice(0,4).map((el, ij) => {
        let character = ('')
        if ((el.characters || [])[0])
          character = (
            <div>
              <div style={{ height: '10px' }}/>
              <span style={{ opacity: '0.7', fontSize: '15px' }}>{el.characters[0]}</span>
            </div>
          )
        let actorImage = (
          <div className="actor-image-background">
            <div style={{ width: '200px', height: '296px', backgroundColor: 'rgba(255,255,255,0.1)' }}/>
          </div>
        )
        if (el.image && !el.image.startsWith('https://cdn.myanimelist.net/images/questionmark'))
          actorImage = (
              <div className="actor-image-background">
                <div style={{ width: '200px', height: '296px' }}>
                  <ImageFadeIn src={el.image} alt={el.name} width={200} style={{ objectFit: 'cover', width: '100%', height: '100%'}}/>
                </div>
              </div>
          )
        return (
          <a className="blank-link" key={'actor-' + ij} href={el.link} target="_blank" rel="noopener noreferrer">
            <div className="actor-block">
              {actorImage}
              <br/>
              {nameFix(el.name)}
              {character}
            </div>
          </a>
        )
      })
    }

    let stills = ('')

    if ((data.stills || []).length) {
      stills = data.stills.slice(0,3).map((el, ij) => {
        return (
          <a className="still blank-link" key={'still-' + ij} href={el.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: ij === 2 ? '0px' : '20px' }}>
            <ImageFadeIn src={el.url} alt={data.name + ' still'} style={{ marginBottom: '15px' }}/>
          </a>
        )
      })
    }

    let reviews = []

    function mapReviews(el, ij) {
        let revAuthor = ('')
        if ((el.critic || {}).name) {
          revAuthor = (
            <>
              <span style={{ fontSize: '15px', opacity: '0.7' }}>{((el.critic || {}).name || 'Anonymous') + ((el.publication || {}).name ? ' (' + el.publication.name + ')' : '')}</span>
              <br/><br/>
            </>
          )
        }
        const reviewHtml = (el.short ? el.short.replace(/(?:\r\n|\r|\n)/g, '<br>') : '') + (reviewsFromMAL ? ' ...' : '')
        return (
          <div style={{ padding: '14px', marginBottom: '15px', backgroundColor: 'rgba(255,255,255,0.1)' }}>
            {revAuthor}
            <span dangerouslySetInnerHTML={{ __html: reviewHtml }} />
            <div style={{ height: '25px' }} />
            <Link className="blank-link rev-button" to={'/review/' + data.ids.anilist + '/' + ij}>read more</Link>
            <div style={{ height: '10px' }} />
          </div>
        )
    }

    let tempReviews

    let reviewsFromMAL = false

    if (!tempReviews && (((data.reviews || {}).anilist || []).audience || []).length)
      tempReviews = data.reviews.anilist.audience

    if (!tempReviews && (((data.reviews || {}).mal || []).audience || []).length) {
      reviewsFromMAL = true
      tempReviews = data.reviews.mal.audience
    }

    if (!tempReviews && (((data.reviews || {}).kitsu || []).audience || []).length)
      tempReviews = data.reviews.kitsu.audience

    if (tempReviews) {
      reviews = tempReviews.map(mapReviews)
    }

    let reviewLink = []

    if (data.consensus) {
      reviewLink.push(
          <a className={'main-links'} href="#consensus">Reviews</a>
      )
    } else if (reviews.length) {
      reviewLink.push(
          <a className={'main-links'} href="#reviews">Reviews</a>
      )
    }

    let mainLinks = []

    if ((((data.trailers || {}).youtube || [])[0] || {}).url) {
      const mainButton = (
        <>
          <a className={'main-links'} href={data.trailers.youtube[0].url} target="_blank" rel="noopener noreferrer">Trailer</a>
        </>
      )
      mainLinks.push(mainButton)
    }

    if (reviewLink.length) {
      const mainButton = (
          <>
            { reviewLink.length ? reviewLink : '' }
          </>
        )

      mainLinks.push(mainButton)
    }

    let watchlistButton = (
      <>
        <span className={'main-links'} onClick={this.addToWatchlist.bind(this)}>+ Watchlist</span>
      </>
    )

    if ((watchlist[data.type + 'WatchlistOrder'] || []).includes(((data || {}).ids || {}).anilist || '')) {
      watchlistButton = (
        <>
          <span className={'main-links'} onClick={this.removeFromWatchlist.bind(this)} style={{ border: '1px solid rgba(255,255,255,0.3)' }}>- Watchlist</span>
        </>
      )
    }

    mainLinks.push(watchlistButton)

    let externalLinks = []

    if (data.ids.imdbUrl)
      externalLinks.push(
        <a key="imdb-link" className={'extra-links'} href={'https://imdb.com' + data.ids.imdbUrl} target="_blank" rel="noopener noreferrer">IMDB</a>
      )

    if (data.ids.metacritic)
      externalLinks.push(
        <a key="mc-link" className={'extra-links'} href={'https://metacritic.com' + data.ids.metacritic} target="_blank" rel="noopener noreferrer">Metacritic</a>
      )

    if (data.ids.rtUrl)
      externalLinks.push(
        <a key="rt-link" className={'extra-links'} href={'https://rottentomatoes.com' + data.ids.rtUrl} target="_blank" rel="noopener noreferrer">Rotten Tomatoes</a>
      )

    if (data.ids.homeUrl)
      externalLinks.push(
        <a key="homepage-link" className={'extra-links'} href={data.ids.homeUrl} target="_blank" rel="noopener noreferrer">Official Website</a>
      )

    let plot = ('')

    if (data.plot) {
      const plotHtml = { __html: data.plot }
      plot = (
        <>
          <br/>
          <br/>
          <div dangerouslySetInnerHTML={plotHtml}/>
        </>
      )
    }

    let addonsNotice = ('')

    if (addons.length < 2)
      addonsNotice = (
        <>
          <div style={{ marginTop: '10px' }}/>
          <span style={{ opacity: '0.7', fontSize: '15px' }}>Add more search addons by pressing the "+" button</span>
          <br/>
        </>
      )

    let addonManagerBox = ('')

    if (this.state.addonManager) {
      const addonManagerOpts = []
      searchAddons.forEach((el, ij) => {
        const newEl = (
          <label key={'addon-manager-' + ij} className="addon-label">
            <Checkbox
              defaultChecked={addons.includes(el.name)}
              onChange={this.addonChange.bind(this, el.name)}
              disabled={false}
            />
            &nbsp;&nbsp; {el.name}
          </label>
        )
        addonManagerOpts.push(newEl)
      })
      addonManagerBox = (
        <div style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', marginTop: '20px', paddingBottom: '5px', width: 'calc(100% - 40px)' }}>
          <h3 style={{ margin: '0', fontSize: '17px' }}>Search addons</h3>
          <br/>
          {addonManagerOpts}
          <br/>
          <span onClick={this.saveAddons.bind(this)} style={{ marginBottom: '15px', marginTop: '7px' }} className={'main-links'}>
            Save
          </span>
        </div>
      )
    }

    let itemImages = []

    if (data.poster)
      itemImages.push(
        <a className="poster blank-link" href={data.poster} target="_blank" rel="noopener noreferrer">
          <ImageFadeIn src={useKitsu.forPosters.includes((data.ids || {}).anilist || '') && data.kitsuPoster ? data.kitsuPoster : data.poster} alt={data.name + ' poster'} width={'100%'} style={{ maxWidth: '100%', width: '100%' }}/>
        </a>
      )

    if (data.backdrop)
      itemImages.push(
        <a className="backdrop blank-link" href={data.backdrop} target="_blank" rel="noopener noreferrer">
          <ImageFadeIn src={data.backdrop} alt={data.name + ' backdrop'} width={'100%'} style={{ maxWidth: '100%', width: '100%' }}/>
        </a>
      )

    let noLeftSide = false

    let noLeftSideSmall = false

    if (!ratings.__html.length || ratings.__html.length === 6) {
      if (!itemImages.length)
        noLeftSide = true
      else if (!data.backdrop)
        noLeftSideSmall = true
    }

    let metaGenres = ('')

    if ((data.genres || []).length) {
      metaGenres = (
        <div className="meta-genres">
          <br/>
          <span style={{ opacity: '0.7' }} dangerouslySetInnerHTML={genres}/>
        </div>
      )
    }

    let mainLinksSpace = (
      <>
        <div style={{ height: '25px' }} />
      </>
    )

    if (!(data.genres || []).length && !data.plot)
      mainLinksSpace = (
        <>
          <br/>
        </>
      )

    let stickyLegal = ('')

    if (!window.localStorage.agreedLegal && !this.state.isAndroid) {
      stickyLegal = (
        <div className="sticky-legal" style={{ display: 'block' }}>
          The use of this service presumes that you agree with the cookie / privacy policies, as well as the disclaimer, which can be found <Link className="blank-link" to={'/legal'} style={{ textDecoration: 'underline' }}>here</Link>.
          <div style={{ height: '15px' }}/>
          <div className="main-links" onClick={this.agreedLegal.bind(this)} style={{ fontSize: '15px', padding: '5px', marginRight: '0', marginLeft: '0'}}>I understand</div>
        </div>
      )
    }

    data.studios = data.studios.filter(el => el !== 'None found, add some')

    return (
      <div className={'App Meta'} style={{ marginTop: '60px' }}>
        <Helmet>
          <title>{this.pageTitle() + ' - Anime Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
        </Helmet>
        <div className="expand-width">
          <div className="sticky" style={{ display: 'block', marginLeft: '-10%', height: '21px' }}>
            <div className="sticky-inner" style={{ width: '80%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{data.name + (data.year ? ' (' + data.year + ')' : '')}</h1>
            </div>
          </div>
          <header className="App-header">
            <br/>
            <Link className="blank-link" style={{ zIndex: '100' }} to="/">
              <img src={images.logo} className="App-logo-meta" alt="Anime Track logo" />
            </Link>
            <br/>
          </header>
          <div className={'header-buttons-holder'}>
            <Link className={'main-links meta-series-button'} style={{ marginBottom: '15px' }} to={'/series/'}>Series</Link>
            <Link className={'main-links meta-movie-button'} style={{ marginRight: '0' }} to={'/movies/'}>Movies</Link>
          </div>
          <div className={'left-side' + (noLeftSideSmall ? ' no-left-side-small' : '')} style={{ display: noLeftSide ? 'none' : 'inline-block' }}>
            <div style={{ display: ratings.__html.length ? 'block' : 'none', fontSize: '16px', backgroundColor: 'rgba(0,0,0,0.2)', padding: '10px 0', opacity: '1' }} dangerouslySetInnerHTML={ratings}/>
            {itemImages}
          </div>
          <div className={'right-side' + (noLeftSideSmall ? ' no-left-side-right' : '')}>
            <div style={{ display: 'inline-block', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', width: 'calc(100% - 40px)' }}>
              <h3 style={{ margin: '0', fontSize: '17px' }}>General</h3>
{ /*              <h1 style={{ display: 'inline-block', fontSize: '22px', margin: '0', padding: '0' }}>{data.name + (data.year ? ' (' + data.year + ')' : '')}</h1> */ }
              {metaGenres}
              {plot}
              {mainLinksSpace}
              {mainLinks}
            </div>
            {addonManagerBox}
            <div style={{ display: this.state.addonManager ? 'none' : 'block', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', marginTop: '20px', paddingBottom: '5px', width: 'calc(100% - 40px)' }}>
              <h3 style={{ margin: '0', fontSize: '17px' }}>Addons</h3>
              {addonsNotice}
              <br/>
              {searchers}
            </div>
          </div>
          <br/>
          <div style={{ display: 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', paddingBottom: '5px' }}>
            {lastRelease}
            {lastEpisode}
            <div style={{ display: !(data.studios || []).length ? 'none' : 'inline-block', marginRight: '30px', marginBottom: '15px' }}>
              <b>Studios</b>
              <div style={{ height: '15px'}}/>
              {(data.studios || []).slice(0, 2).join(', ')}
            </div>
            <div style={{ display: !data.runtime || data.runtime === 'Unknown' ? 'none' : 'inline-block', marginRight: '30px', marginBottom: '15px' }}>
              <b>Runtime</b>
              <div style={{ height: '15px'}}/>
              {data.runtime}
            </div>
            <div style={{ display: !data.status ? 'none' : 'inline-block', marginRight: '30px', marginBottom: '15px' }}>
              <b>Status</b>
              <div style={{ height: '15px'}}/>
              {capitalizeFirstLetter(data.status)}
            </div>
            <div style={{ display: 'inline-block', marginRight: '30px', marginBottom: '15px' }}>
              <b>Dubbed</b>
              <div style={{ height: '15px'}}/>
              {data.hasDub ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="actors-holder" style={{ display: !actors.length ? 'none' : 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', paddingBottom: '0' }}>
              <div style={{ textAlign: 'left' }}>
                <h3 style={{ margin: '0', fontSize: '17px' }}>Characters</h3>
              </div>
              <br/>
              {actors}
          </div>
          <div style={{ display: !stills.length ? 'none' : 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', paddingBottom: '5px' }}>
              <h3 style={{ margin: '0', fontSize: '17px' }}>Stills</h3>
              <br/>
              <div className="stills-holder">
                {stills}
              </div>
          </div>
          <div>
            <a id="consensus" style={{ position: 'relative', top: '-61px' }}/>
            <div style={{ display: !data.consensus ? 'none' : 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px' }}>
                <h3 style={{ margin: '0', fontSize: '17px' }}>Critics Consensus</h3>
                <br/>
                {data.consensus}
            </div>
          </div>
          <div>
            <a id="reviews" style={{ position: 'relative', top: '-61px' }}/>
            <div style={{ display: !reviews.length ? 'none' : 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', paddingBottom: '5px' }}>
                <h3 style={{ margin: '0', fontSize: '17px' }}>Reviews</h3>
                <br/>
                {reviews}
            </div>
          </div>
{/*
          <div style={{ display: !externalLinks.length ? 'none' : 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px', paddingBottom: '5px' }}>
              <h3 style={{ margin: '0', fontSize: '17px' }}>Links</h3>
              <br/>
              {externalLinks}
          </div>
*/}
          <div style={{ height: '50px' }}/>
        </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
        {stickyLegal}
      </div>
    );
  }
}
