import React from 'react';
import images from './images.js';
import './App.css';
import { Link } from 'react-router-dom';
import Select from "react-select";
import InfiniteScroll from 'react-infinite-scroller';
import ImageFadeIn from "react-image-fade-in"
import Helmet from 'react-helmet'
import { isAndroid as isAndroidWeb } from 'react-device-detect'
import useKitsu from './useKitsu.js'

const refreshTime = 30 * 60 * 1000 // 30min

const paginate = 30

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isDub(el) {
  return !!el.hasDub
}

export default class App extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          data: [],
          hasMoreItems: true,
          limit: paginate,
      };
  }
  processList(data) {
      let filters = false
      let locTypes = this.props.location.pathname.split('/')
      locTypes.shift()
      const type = locTypes[0]
      const listType = type === 'movies' ? 'movie' : type
      const order = locTypes[1]
      let audioSelect = 'Any'
      if (locTypes[2]) {
        filters = true
        audioSelect = capitalizeFirstLetter(locTypes[2])
      }
      let genreSelect = 'none'
      if (locTypes[3]) {
        filters = true
        genreSelect = capitalizeFirstLetter(locTypes[3])
        if (genreSelect === 'Sci-fi')
          genreSelect = 'Sci-Fi'
      }
      const elements = data[listType]
      let ordered
      if (order === 'new') {
        ordered = Object.values(elements).sort((a, b) => {
          const dateA = a.pubdate
          const dateB = b.pubdate
          return dateA < dateB ? 1 : dateA > dateB ? -1 : 0
        })
      } else if (order === 'popular') {
        ordered = Object.values(elements).sort((a, b) => {
          const popularityA = a.meta.popularityRank || 1000000
          const popularityB = b.meta.popularityRank || 1000000
          return popularityB < popularityA ? 1 : popularityB > popularityA ? -1 : 0          
        })
        console.log(ordered)
      } else if (order === 'best') {
        ordered = Object.values(elements).filter(el => {

          let atLeastCount = 0

          if (((((el.meta || {}).ratings || {}).mal || {}).audience || {}).score)
            atLeastCount++

          if (((((el.meta || {}).ratings || {}).anilist || {}).audience || {}).score)
            atLeastCount++

          if (((((el.meta || {}).ratings || {}).kitsu || {}).audience || {}).score)
            atLeastCount++

          return !!(atLeastCount > 1)
        }).map(el => {
          const ratings = []
          el.overall = 0
          if (Object.keys((el.meta || {}).ratings || {}).length) {
            Object.values(el.meta.ratings).forEach(rating => {
              if ((rating.critics || {}).score) {
                ratings.push(rating.critics.score)
              } else if ((rating.audience || {}).score) {
                ratings.push(rating.audience.score * 10)
              }
            })
            if (ratings.length) {
              ratings.forEach(elm => {
                el.overall += elm
              })
              el.overall = el.overall / ratings.length
            }
          }

          return el
        }).sort((a, b) => {
          return a.overall < b.overall ? 1 : a.overall > b.overall ? -1 : 0          
        })
      }

      ordered = ordered.filter(el => { return (el.meta && el.meta.name) })

      const genreOpts = [
        { value: 'none', label: 'Any Genre' }
      ]

      const noGenreDups = []

      ordered.forEach(el => {
        if (((el.meta || {}).genres || []).length) {
          el.meta.genres.forEach(genre => {
            if (!noGenreDups.includes(genre))
              noGenreDups.push(genre)
          })
        }
      })

      noGenreDups.sort().forEach(genre => {
        genreOpts.push({
          value: genre, label: genre
        })
      })
      const audioOpts = [
        {
          value: 'Any', label: 'Any Audio'
        },
//        {
//          value: 'Japanese', label: 'Japanese'
//        },
        {
          value: 'English', label: 'English'
        },
      ]

      const total = ordered.length

      const limited = ordered.slice(0, this.state.limit)

      const isAndroid = ((window.navigator || {}).userAgent || '').endsWith('AndroidApp')

      this.setState({ initLoading: false, filters, all: ordered, ordered: limited, hasMoreItems: !!(limited.length < total), type, order, genreOpts, genreSelect, audioOpts, audioSelect, isAndroid })
  }
  componentWillMount() {

      const data = require('./data/lists.json')

      this.processList(data)

  }
  moreFilters() {
    this.setState({ filters: true })
  }
  pageTitle() {
    let docTitle = ''
    if (this.state.order === 'new') {
      docTitle += 'New '
    } else if (this.state.order === 'popular') {
      docTitle += 'Popular '
    } else if (this.state.order === 'best') {
      docTitle += 'Best '
    }
    if (this.state.audioSelect === 'Any') {
      // add nothing to title
    } else if (this.state.audioSelect === 'English') {
      docTitle += 'Dubbed '
    } else {
      docTitle += 'Subbed '
    }
    if (this.state.genreSelect !== 'none') {
      docTitle += this.state.genreSelect + ' '
    }
    docTitle += 'Anime '
    if (this.state.type === 'movies') {
      docTitle += 'Movies '
    } else if (this.state.type === 'series') {
      docTitle += 'Series '
    }
    return docTitle.slice(0, -1)
  }
  pageKeywords() {
    const keywords = []
    keywords[0] = ''
    if (this.state.order === 'new') {
      keywords[0] += 'new '
      keywords[1] = 'new'
    } else if (this.state.order === 'popular') {
      keywords[0] += 'popular '
      keywords[1] = 'popular'
    } else if (this.state.order === 'best') {
      keywords[0] += 'best '
      keywords[1] = 'best'
    }
    if (this.state.audioSelect === 'Any') {
      // add nothing to keys / desc
    } else if (this.state.audioSelect === 'English') {
      keywords[0] += 'dubbed '
      keywords[2] = 'dubbed'
    } else {
      keywords[0] += 'subbed '
      keywords[2] = 'subbed'
    }
    if (this.state.genreSelect !== 'none') {
      keywords[0] += this.state.genreSelect.toLowerCase() + ' '
      keywords[3] = this.state.genreSelect.toLowerCase()
    }
    if (this.state.type === 'movies') {
      keywords[0] += 'movies '
      keywords[4] = 'movies'
    } else if (this.state.type === 'series') {
      keywords[0] += 'series '
      keywords[4] = 'series'
    }
    keywords[0] += 'torrent releases'
    keywords[5] = 'track torrents'
    keywords[6] = 'anime track'
    return keywords.filter(el => !!el).join(', ')
  }
  pageDescription() {
    let docDesc = 'Find '
    if (this.state.order === 'new') {
      docDesc += 'new '
    } else if (this.state.order === 'popular') {
      docDesc += 'popular '
    } else if (this.state.order === 'best') {
      docDesc += 'the best '
    }
    if (this.state.genreSelect !== 'none') {
      docDesc += this.state.genreSelect.toLowerCase() + ' '
    }
    docDesc += 'anime '
    if (this.state.type === 'movies') {
      docDesc += 'movie '
    } else if (this.state.type === 'series') {
      docDesc += 'series '
    }
    docDesc += 'torrent releases with MAL, Anilist, Kitsu scores.'
    return docDesc
  }
  updateUrl(genre, audio) {
    let newUrl = '/' + this.state.type + '/' + this.state.order
    if (genre !== 'none') {
      newUrl += '/' + audio.toLowerCase() + '/' + genre.toLowerCase()
    } else if (audio !== 'Any') {
      newUrl += '/' + audio.toLowerCase()
    }
    if (typeof (window.history || {}).pushState !== 'undefined')
      window.history.replaceState(null, null, newUrl)
  }
  handleAudioSelect(e) {
    this.updateUrl(this.state.genreSelect, e.value)
    this.setState({
        audioSelect: e.value,
    });
  }
  handleGenreSelect(e) {
    this.updateUrl(e.value, this.state.audioSelect)
    this.setState({
        genreSelect: e.value,
    });
  }
  loadMore() {
    if (this.loading)
      return
    this.loading = true
    const limit = this.state.limit + paginate;
    const total = this.state.all.length;
    const limited = this.state.all.slice(0, limit);
    this.setState({ ordered: limited, hasMoreItems: !!(limited.length < total) })
    setTimeout(() => {
      this.loading = false
    })
  }
  agreedLegal() {
    window.localStorage.agreedLegal = '1'
    this.setState({ agreedLegal: true })
  }
  render() {
    if (this.state.initLoading) {
      return (
        <div className="loading">
          <div />
          <div />
        </div>
      )
    }
    let elements = []
    let audioOrder = []
    if (this.state.audioSelect === 'English') {
      // allow only english:
      audioOrder = this.state.ordered.filter(el => isDub(el))
    } else if (this.state.audioSelect === 'Japanese') {
      audioOrder = this.state.ordered.filter(el => !isDub(el))
    } else if (this.state.audioSelect === 'Any') {
      // no ordering needed
      audioOrder = this.state.ordered
    }
    audioOrder.forEach((el, ij) => {
      if (this.state.genreSelect !== 'none') {
        if (!((el.meta || {}).genres || []).length)
          return
        if (!el.meta.genres.includes(this.state.genreSelect))
          return
      }
      let ratings = { __html: '' }
      if ((((el.meta.ratings || {}).mal || {}).audience || {}).score) {
        ratings.__html += '<div class="mal-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + el.meta.ratings.mal.audience.score.toFixed(1) + '/10'
      }
      if ((((el.meta.ratings || {}).anilist || {}).audience || {}).score) {
        if (ratings.__html)
          ratings.__html += '&nbsp;&nbsp;&nbsp;'
        ratings.__html += '<div class="anilist-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + el.meta.ratings.anilist.audience.score + '%'
      }
      if ((((el.meta.ratings || {}).kitsu || {}).audience || {}).score) {
        if (ratings.__html)
          ratings.__html += '&nbsp;&nbsp;&nbsp;'
        ratings.__html += '<div class="kitsu-small" style="vertical-align: middle; margin-top: -3px"></div>&nbsp;&nbsp;' + el.meta.ratings.kitsu.audience.score.toFixed(1) + '%'
      }
      if (!ratings.__html)
        ratings.__html = '&nbsp;'
      const genres = { __html: (el.meta.genres || []).length ? el.meta.genres.slice(0, 3).join(' / ') : '&nbsp;' }
      const newEl = (
          <Link className="blank-link" to={'/title/' + el.ids.anilist}>
            <div key={ij} style={{ display: 'inline-block', margin: '15px', maxWidth: '250px', width: '250px', verticalAlign: 'top' }}>
                <div style={{ display: ratings.__html.length ? 'block' : 'none', fontSize: '16px', backgroundColor: 'rgba(0,0,0,0.3)', padding: '10px 0', opacity: '0.8' }} dangerouslySetInnerHTML={ratings}/>
                <div style={{ width: '250px', height: '370px' }}>
                  <ImageFadeIn src={useKitsu.forPosters.includes((el.ids || {}).anilist || '') && el.meta.kitsuPoster ? el.meta.kitsuPoster : el.meta.poster} alt={(el.meta || {}).name + ' poster'} width={250} style={{ objectFit: 'cover', width: '100%', height: '100%'}}/>
                </div>
                <div style={{ fontSize: '15px', backgroundColor: 'rgba(0,0,0,0.3)', padding: '10px 0', opacity: '0.8' }}>
                  <span style={{ opacity: '0.7' }} dangerouslySetInnerHTML={genres}/>
                </div>
                <br/>
                <div>{(el.meta.name.length > 40 ? el.meta.name.substr(0, 40) + '...' : el.meta.name) + (el.meta.year ? ' (' + el.meta.year + ')' : '')}</div>
                <br/>
                <br/>
            </div>
          </Link>
      );
      elements.push(newEl)
    })

    let noItemsMessage = ('')

    if (!elements.length) {
      noItemsMessage = (
        <div style={{ padding: '20px', margin: '20px 0', textAlign: 'center', background: 'rgba(0,0,0,0.3)', opacity: '0.4', marginBottom: '45px' }}>
          No results.
        </div>
      )
    }

    let stickyLegal = ('')

    if (!window.localStorage.agreedLegal && !this.state.isAndroid) {
      stickyLegal = (
        <div className="sticky-legal" style={{ display: 'block' }}>
          The use of this service presumes that you agree with the cookie / privacy policies, as well as the disclaimer, which can be found <Link className="blank-link" to={'/legal'} style={{ textDecoration: 'underline' }}>here</Link>.
          <div style={{ height: '15px' }}/>
          <div className="main-links" onClick={this.agreedLegal.bind(this)} style={{ fontSize: '15px', padding: '5px', marginRight: '0', marginLeft: '0'}}>I understand</div>
        </div>
      )
    }

    return (
      <div className="App">
        <Helmet>
          <title>{this.pageTitle() + ' on Torrents - Anime Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
          <meta
            name="keywords"
            content={this.pageKeywords()}
          />
        </Helmet>
        <header className="App-header" style={{ marginTop: '65px'}}>
          <div className="sticky" style={{ display: 'block' }}>
            <div className="sticky-inner" style={{ width: '95%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1>{this.pageTitle()}</h1>
            </div>
          </div>
          <br/>
          <div className="android-app-msg" style={{ display: !this.state.isAndroid && isAndroidWeb ? 'block' : 'none' }}>Check out our Android app! <a className="blank-link" href="https://easyupload.io/f0e119" target="_blank" style={{ textDecoration: 'underline' }}>Download</a></div>
          <Link className="blank-link" to="/">
            <img src={images.logo} className="App-logo" alt="Anime Track logo" />
          </Link>
          <span className="slogan">The front page of anime torrents</span>
          <br/>
        </header>
        <Link className={('main-links ' + (this.state.type === 'series' ? 'main-links-selected' : ''))} to={'/series/' + this.state.order}>Series</Link>
        <Link className={('main-links ' + (this.state.type === 'movies' ? 'main-links-selected' : ''))} to={'/movies/' + this.state.order}>Movies</Link>
        <div className="mobile-compliance-menu-1" />
        <div className="mobile-compliance-menu-2" />
        <Link className={('main-links ' + (this.state.order === 'new' ? 'main-links-selected' : ''))} to={'/' + this.state.type + '/new'}>New</Link>
        <Link className={('main-links ' + (this.state.order === 'popular' ? 'main-links-selected' : ''))} to={'/' + this.state.type + '/popular'}>Popular</Link>
        <Link className={('main-links ' + (this.state.order === 'best' ? 'main-links-selected' : ''))} style={{ marginRight: '0px' }} to={'/' + this.state.type + '/best'}>Best</Link>
        <div className="mobile-compliance-menu-3" />
        <div className="mobile-compliance-menu-4" />
        <div className="main-links" onClick={this.moreFilters.bind(this)} style={{ fontSize: '15px', border: 'none', padding: '5px', display: this.state.filters ? 'none' : 'inline-block'}}>+ More Filters</div>
        <br/><br/>
        <div className="options-hold" style={{ display: this.state.filters ? 'block' : 'none' }}>
          <div className="genre-options">
            <Select
              onChange={this.handleGenreSelect.bind(this)}
              menuPlacement="auto"
              defaultValue={this.state.genreOpts[Object.values(this.state.genreOpts).map(el => el.value).indexOf(this.state.genreSelect)]}
              label="Genre"
              options={this.state.genreOpts}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary50: "rgba(255,255,255,0.2)",
                  primary25: "rgba(0,0,0,0.5)",
                  primary: "rgba(255,255,255,0.8)",
                  neutral0: "#282c34",
                  neutral70: "white",
                  neutral80: "white",
                  neutral90: "white"
                },
                option: (provided, state) => ({
                  ...provided,
                  color: 'white'
                })
              })}
            />
          </div>
          <div className="audio-options">
            <Select
              onChange={this.handleAudioSelect.bind(this)}
              menuPlacement="auto"
              defaultValue={this.state.audioOpts[Object.values(this.state.audioOpts).map(el => el.value).indexOf(this.state.audioSelect)]}
              label="Audio"
              options={this.state.audioOpts}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary50: "rgba(255,255,255,0.2)",
                  primary25: "rgba(0,0,0,0.5)",
                  primary: "rgba(255,255,255,0.8)",
                  neutral0: "#282c34",
                  neutral70: "white",
                  neutral80: "white",
                  neutral90: "white"
                },
                option: (provided, state) => ({
                  ...provided,
                  color: 'white'
                })
              })}
            />
          </div>
        </div>
        {noItemsMessage}
        <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={this.state.hasMoreItems}>
          {elements}
        </InfiniteScroll>
        <div style={{ height: '10px' }} />
{/*
        <div className="reached-end" style={{ marginBottom: '50px', display: this.state.order === 'popular' ? 'block' : 'none' }}>
          Popularity is sorted by seeds, only the top 100 torrents are taken into account.
        </div>
*/}
        <div className="reached-end">
          In order to guarantee fresh content, we show releases from the last 90 days only.
        </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
        {stickyLegal}
      </div>
    );
  }
}
