import React from 'react';
import './App.css';
import images from './images.js';
import { Link, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet'

export default class App extends React.Component {
  componentWillMount() {

      const processMeta = data => {
        const isAndroid = ((window.navigator || {}).userAgent || '').endsWith('AndroidApp')
        this.setState({ initLoading: false, item: data, isAndroid, reviewId })
      }

      let locTypes = this.props.location.pathname.split('/')
      const anilistId = locTypes[locTypes.length -2]
      const reviewId = locTypes[locTypes.length -1]
      try {
        const data = require('./data/meta/' + anilistId + '.json')
        processMeta(data)
      } catch(e) {
        this.setState({ initLoading: true })
        fetch('https://api.anime-track.app/meta/' + anilistId + '.json')
          .then(res => res.json())
          .then(
            (result) => {
              processMeta(result)
            },
            (error) => {
              this.setState({ initLoading: false })
              // maybe handle errors in the future
            }
          )
      }
  }
  pageTitle() {
     let docTitle = 'Review for '

     if (this.state.item.name)
        docTitle += this.state.item.name

      if (this.state.item.year)
        docTitle += ' ' + this.state.item.year

      return docTitle
  }
  pageDescription() {
    let docDesc = 'Read audience review for '

    if (this.state.item.name)
      docDesc += this.state.item.name

    if (this.state.item.year)
      docDesc += ' ' + this.state.item.year

    docDesc += '.'

    return docDesc
  }
  agreedLegal() {
    window.localStorage.agreedLegal = '1'
    this.setState({ agreedLegal: true })
  }
  goBack() {
    this.props.history.goBack()
  }
  render() {

    if (this.state.initLoading) {
      return (
        <div className="loading">
          <div />
          <div />
        </div>
      )
    }

    const data = this.state.item

//    if (!data || !Object.keys(data).length) {
//      return <Redirect to={'/404'} />
//    }

    let tempReviews

    let reviewFromMal = false

    if (!tempReviews && (((data.reviews || {}).anilist || []).audience || []).length)
      tempReviews = data.reviews.anilist.audience

    if (!tempReviews && (((data.reviews || {}).mal || []).audience || []).length) {
      reviewFromMal = true
      tempReviews = data.reviews.mal.audience
    }

    if (!tempReviews && (((data.reviews || {}).kitsu || []).audience || []).length)
      tempReviews = data.reviews.kitsu.audience

    let review = { __html: '' }

    if (tempReviews && tempReviews[this.state.reviewId]) {
      let reviewHtml = tempReviews[this.state.reviewId].quote
      if (reviewFromMal) {
        reviewHtml = reviewHtml.replace(tempReviews[this.state.reviewId].short, '<span style="opacity: 0.7">' + tempReviews[this.state.reviewId].short + '</span>' + '&nbsp;')
      }
      reviewHtml = reviewHtml.replace(/(?:\r\n|\r|\n)/g, '<br>')
      review = { __html: reviewHtml }
    }

    if (!review) {
      return <Redirect to={'/404'} />
    }


    let stickyLegal = ('')

    if (!window.localStorage.agreedLegal && !this.state.isAndroid) {
      stickyLegal = (
        <div className="sticky-legal" style={{ display: 'block' }}>
          The use of this service presumes that you agree with the cookie / privacy policies, as well as the disclaimer, which can be found <Link className="blank-link" to={'/legal'} style={{ textDecoration: 'underline' }}>here</Link>.
          <div style={{ height: '15px' }}/>
          <div className="main-links" onClick={this.agreedLegal.bind(this)} style={{ fontSize: '15px', padding: '5px', marginRight: '0', marginLeft: '0'}}>I understand</div>
        </div>
      )
    }

    return (
      <div className={'App Meta'} style={{ marginTop: '60px' }}>
        <Helmet>
          <title>{this.pageTitle() + ' - Anime Track'}</title>
          <meta
            name="description"
            content={this.pageDescription()}
          />
        </Helmet>
        <div className="expand-width">
          <div className="sticky" style={{ display: 'block', marginLeft: '-10%', height: '21px' }}>
            <div className="sticky-inner" style={{ width: '80%' }}>
              <span style={{ float: 'right'}}><Link className={'blank-link meta-watchlist-button'} to={'/watchlist'}>My watchlist</Link></span>
              <h1 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{'Review for ' + data.name + (data.year ? ' (' + data.year + ')' : '')}</h1>
            </div>
          </div>
          <header className="App-header">
            <br/>
            <Link className="blank-link" style={{ zIndex: '100' }} to="/">
              <img src={images.logo} className="App-logo-meta" alt="Anime Track logo" />
            </Link>
            <br/>
          </header>
          <div className={'header-buttons-holder'}>
            <Link className={'main-links meta-series-button'} style={{ marginBottom: '15px' }} to={'/series/'}>Series</Link>
            <Link className={'main-links meta-movie-button'} style={{ marginRight: '0' }} to={'/movies/'}>Movies</Link>
          </div>
          <div className={'main-links'} style={{ marginRight: '0' }} onClick={this.goBack.bind(this)}>Go back</div>
          <div dangerouslySetInnerHTML={review} style={{ display: 'inline-block', verticalAlign: 'top', textAlign: 'left', width: 'calc(100% - 40px)', maxWidth: '100%', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '20px' }} />
          <div style={{ height: '20px' }}/>
          <div className={'main-links'} style={{ marginRight: '0' }} onClick={this.goBack.bind(this)}>Go back</div>
          <div style={{ height: '50px' }}/>
        </div>
        <div className="footer">
          <ul>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/legal'} style={{ fontSize: '16px', opacity: '0.9' }}>Legal</Link>
            </li>
            <li>
              <Link className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" to={'/api-docs'} style={{ fontSize: '16px', opacity: '0.9' }}>API</Link>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/donate" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Donate</a>
            </li>
            <li>
              <a className={('blank-link meta-watchlist-button')} rel="noopener noreferrer" href="https://powder.media/" target="_blank" style={{ fontSize: '16px', opacity: '0.9' }}>Stream torrents</a>
            </li>
          </ul>
        </div>
        {stickyLegal}
      </div>
    );
  }
}
